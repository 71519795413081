import { Injectable } from '@angular/core';
import { BehaviorSubject, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private alertState = new BehaviorSubject<{ show: boolean; text: string; backgroundColor: string; icon: string; }>({
    show: false,
    text: '',
    backgroundColor: '',
    icon: '',
  });

  alertState$ = this.alertState.asObservable();

  showAlertSuccess(text: string,  autoHide: boolean = true) {
    this.alertState.next({ show: true, text, backgroundColor: '#e1fcde',icon: 'assets/icons/Positive Solid.svg' });
    if (autoHide) {
      this.hideAlertAfter(5000);
    }
  }
  showAlertError(text: string, backgroundColor: string = '#ffd4d8', icon: string = 'assets/icons/Negative Solid.svg') {
    this.alertState.next({ show: true, text, backgroundColor,icon });
    this.hideAlertAfter(5000);
  }
  hideAlert() {
    this.alertState.next({ show: false, text: '', backgroundColor: '', icon:''});
  }

  private hideAlertAfter(duration: number) {
    timer(duration).pipe(take(1)).subscribe(() => {
      this.hideAlert();
    });
  }
}
